// PasswordPrompt.js
import React, { useState } from 'react';

const PasswordPrompt = ({ onPasswordSubmit }) => {
  const [password, setPassword] = useState('');
  const [error, setError] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (password === 'A8!kop7') {
      onPasswordSubmit(true);
    } else {
      setError(true);
    }
  };

  return (
    <div className="password-popup">
      
      <form onSubmit={handleSubmit}>
        <input
          type="password"
          placeholder="Password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          required
        />
        <button className='submit-password' type="submit">Submit</button>
      </form>
      {error && <p className="error-message">Incorrect password!</p>}
    </div>
  );
};

export default PasswordPrompt;
